<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" pageclass="darker">
      <template #text-content>
        <div class="cyb-login-misc splash">
          <div class="text-box">
            <div class="logo"><img src="/images/thinfinity-white.svg" /></div>
            <div class="content">
              <div class="base-template">
                <h1><span>{{ $t('emailconfirmation.title') }}</span></h1>
                <p v-if="!processed">{{ $t('emailconfirmation.message') }}</p>
                <div v-else>
                  <div v-if="verified">
                    <p>{{ $t('emailconfirmation.successMessage.line1') }}</p>
                    <p>{{ $t('emailconfirmation.successMessage.line2') }}</p>
                    <p>{{ $t('emailconfirmation.successMessage.line3') }}</p>
                  </div>
                  <div v-else>
                    <p>{{ $t('emailconfirmation.errorMessage.line1') }}</p>
                  </div>
                </div>
                <div v-if="processed" class="outline-button">
                  <p><br/></p>
                  <div><router-link to="/Home">{{this.$t("emailconfirmation.goToLogin")}}</router-link></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import { authService } from '../services/auth.service';
import LoginForm from '../components/LoginForm.vue';

export default {
  components: {
    LoginForm
  },
  data() {
    return {
      message: '',
      verified: false,
      processed: false,
    };
  },
  mounted() {
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      authService
        .confirmEmail(this.$route.params.usertoken)
        .then(() => {
          this.verified = true;
          this.processed = true;
        })
        .catch(() => {
          this.verified = false;
          this.processed = true;
        });
    }
  },
};
</script>