<template>
  <div class="layer1 fullscreen" :class="{'with-form': hasForm}">&nbsp;</div>
  <div class="layer2 fullscreen" :class="pageclass">
    <slot name="text-content">
    <div class="cyb-login-misc">
      <div class="text-box">
        <div class="logo"><img src="/images/thinfinity-white.svg" /></div>
        <div class="content">
          <slot name="misc-content">
            <h1><span>{{ $t('login.welcomeTitle1') }} {{ $t('login.welcomeTitle2') }}</span></h1>
            <p>{{ $t('login.welcomeMessage') }}</p>
            <p>{{ $t('login.welcomeMessage2') }}</p>
            <ul>
              <li>{{$t('login.welcomeFeatures[0]')}}</li>
              <li>{{$t('login.welcomeFeatures[1]')}}</li>
              <li>{{$t('login.welcomeFeatures[2]')}}</li>
              <li>{{$t('login.welcomeFeatures[3]')}}</li>
              <li>{{$t('login.welcomeFeatures[4]')}}</li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
    <div class="cyb-login-form" v-if="hasForm">
      <div class="header-form">
        <div class="header-logo"><img src="/images/thinfinity-white.svg" /></div>
        <h1>{{ $t('login.welcomeTitle1') }} {{ $t('login.welcomeTitle2') }}</h1>
      </div>
      <div class="content-form">
        <form @submit.prevent="handleSubmit" :name="formName">
          <slot name="form"></slot>
        </form>
        
      </div>
      
    </div>
    </slot>
  </div>
  <div class="cyb-login-footer">
    <slot name="footer">
      <div v-for="item in footerItems" :key="item" :class="item.extra">
        <a target="_blank" :href="item.value">{{item.description}}</a>
        <span>|</span>
      </div>
      <span>&copy; 2021 Cybele Software, Inc. </span><span class="hiddenmini"> All Rights Reserved.</span>
    </slot>
  </div>
  <beta-version/>
</template>
<script>
import { apiConfig } from '../config/backend-api';
import BetaVersion from './BetaVersion.vue';

const footerLinks = [
  { "key": "howItWorks", "extra": "hiddenmini" },
  { "key": "help", "extra": "hiddenmini" },
  { "key": "contact", "extra": "hiddenmini" },
  { "key": "support" , "extra": "" },
  { "key": "privacyPolicy", "extra": "" }
]

export default {
  components: { BetaVersion },
  props: { "hasForm": {default: true }, "pageclass": {default:""}, "formName": {default:"loginform"} },
  emits: ["sendSubmit"],
  computed: {
    footerItems: function() {
      let items = {};
      for (let i=0; i < footerLinks.length; i++) {
        let value = apiConfig.getConfigUrlValue(footerLinks[i].key);
        if (value) {
          items[footerLinks[i].key] = { "description": this.$t('login.footer.'+footerLinks[i].key), "value": value, "extra": footerLinks[i].extra };
        }
      }
      items["thinfinity"] = {"description": "Thinfinity.com", "value": "https://www.thinfinity.com/", "extra": "hiddenmini" }
      return items;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("sendSubmit");
    }
  }
}
</script>